<template>
  <div>
    <NuxtLoadingIndicator :throttle="0" :color="'#FF003C'" :height="5" />

    <ClientOnly fallbackTag="div">
      <Transition name="fade" appear>
        <main
          v-if="lifecycleStore.initialized"
          :class="hidePadding ? '' : 'pb-32'"
        >
          <div>
            <slot />
          </div>
        </main>
      </Transition>
      <template #fallback>
        <LoadingLayout />
      </template>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
const lifecycleStore = useLifecycleStore();

const route = useRoute();

const hidePadding = computed(() => {
  return (
    route.name === "frame-slug" ||
    route.name === "journey" ||
    route.name === "journey-shared-id" ||
    route.name === "index"
  );
});
</script>
